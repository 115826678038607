<template>
  <div>
    <v-row class="heading ma-0">
      <v-col cols="12" lg="4" class="d-flex">
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              @click="callBackRoute"
              v-bind="attrs"
              v-on="on"
              class="mr-1"
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </template>
          <span>Back</span>
        </v-tooltip>
        <div>
          <div>
            <Tooltip
              tooltipTitle="Delivery Advices Number"
              v-if="selectedDaDetails.da_no"
              :tooltipText="selectedDaDetails.da_no"
              top
              tooltipClass="text-h5"
            />
            <span class="text-h5">&nbsp;|&nbsp;</span>
            <Tooltip
              tooltipTitle="BOE Number"
              v-if="selectedDaDetails.boe"
              :tooltipText="selectedDaDetails.boe"
              top
              tooltipClass="text-h5"
            />
          </div>
          <div v-if="selectedDaDetails.movement_date_time">
            <span class="text-caption">Movement Date &amp; Time </span>
            <span class="font-weight-medium">
              {{ selectedDaDetails.movement_date_time | formatDate(true) }}
            </span>
          </div>
        </div>

        <div class="self-align-top">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-on="on"
                v-bind="attrs"
                class="mx-2"
                @click="getDaDetails('previous')"
              >
                mdi-arrow-left-drop-circle
              </v-icon>
            </template>
            <span>Previous</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-on="on"
                v-bind="attrs"
                class="mx-2"
                @click="getDaDetails('next')"
              >
                mdi-arrow-right-drop-circle
              </v-icon>
            </template>
            <span>Next</span>
          </v-tooltip>
        </div>
      </v-col>

      <v-col cols="12" lg="4">
        <div class="d-flex align-center mb-1">
          <Tooltip
            tooltipTitle="Job Type"
            v-if="selectedDaDetails.job_type"
            :tooltipText="selectedDaDetails.job_type"
            top
            tooltipClass="text-subtitle-2"
          />

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-chip small class="ml-2" v-bind="attrs" v-on="on">
                {{ selectedDaDetails.status }}
              </v-chip>
            </template>
            <span>Status</span>
          </v-tooltip>

          <v-tooltip top v-if="selectedDaDetails.cancellation_remark">
            <template v-slot:activator="{ on, attrs }">
              <v-chip small class="ml-2" v-bind="attrs" v-on="on">
                {{ selectedDaDetails.cancellation_remark }}
              </v-chip>
            </template>
            <span>Cancellation Remark</span>
          </v-tooltip>
        </div>

        <div v-if="selectedDaDetails.created" class="d-flex align-baseline">
          <div>
            <span class="text-caption">Created : </span>
            <span class="font-weight-medium">
              {{ selectedDaDetails.created | formatDate(true) }}
            </span>
          </div>

          <div class="ml-4" v-if="selectedDaDetails.completed_on">
            <span class="text-caption">Completed : </span>
            <span class="font-weight-medium">
              {{ selectedDaDetails.completed_on | formatDate(true) }}
            </span>
          </div>
        </div>
      </v-col>

      <v-col cols="12" lg="4">
        <v-row>
          <v-col cols="6">
            <table class="Normal-Table">
              <tbody>
                <tr v-if="selectedDaDetails.order_no">
                  <td class="text-caption">Order No</td>
                  <td class="px-2">:</td>
                  <th class="font-weight-medium">
                    {{ selectedDaDetails.order_no }}
                  </th>
                </tr>
                <tr v-if="selectedDaDetails.po_no">
                  <td class="text-caption">Purchase Order No</td>
                  <td class="px-2">:</td>
                  <th class="font-weight-medium">
                    {{ selectedDaDetails.po_no }}
                  </th>
                </tr>

                <tr v-if="selectedDaDetails.is_sub_hire">
                  <td class="text-caption">Is SubHire</td>
                  <td class="px-2">:</td>
                  <th class="font-weight-medium">
                    {{ selectedDaDetails.is_sub_hire }}
                  </th>
                </tr>
              </tbody>
            </table>
          </v-col>
          <v-col cols="6">
            <table class="Normal-Table">
              <tbody>
                <tr v-if="selectedDaDetails.shipment_no">
                  <td class="text-caption">Shipment No</td>
                  <td class="px-2">:</td>
                  <th class="font-weight-medium">
                    {{ selectedDaDetails.shipment_no }}
                  </th>
                </tr>
                <tr v-if="selectedDaDetails.vessel_no">
                  <td class="text-caption">Vessel No</td>
                  <td class="px-2">:</td>
                  <th class="font-weight-medium">
                    {{ selectedDaDetails.vessel_no }}
                  </th>
                </tr>
              </tbody>
            </table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="ma-0 subHeading" justify="space-between">
      <v-col cols="12" lg="auto" class="py-1 d-flex">
        <div class="d-flex align-center mr-12">
          <div>
            <span class="text-caption">Branch</span>
            <p class="font-weight-medium mb-0">
              {{ selectedDaDetails.branch_name }}
            </p>
          </div>
          <v-divider vertical class="mx-3"></v-divider>
          <div>
            <span class="text-caption">Depot</span>
            <p class="font-weight-medium mb-0">
              {{ selectedDaDetails.depot_name }}
            </p>
          </div>
          <v-divider vertical class="mx-3"></v-divider>
          <div>
            <span class="text-caption">Hazmat</span>
            <p class="font-weight-medium mb-0">
              {{ selectedDaDetails.is_hazmat }}
            </p>
          </div>
          <v-divider vertical class="mx-3"></v-divider>
          <div>
            <span class="text-caption">Genset</span>
            <p class="font-weight-medium mb-0">
              {{ selectedDaDetails.has_genset }}
            </p>
          </div>
        </div>
        <v-menu open-on-hover right offset-y v-if="selectedDaDetails">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" class="mx-2">
              mdi-map-marker-distance
            </v-icon>
          </template>
          <v-card>
            <v-card-title
              class="secondary white--text text-subtitle-1 font-weight-medium py-1"
            >
              Route Address
            </v-card-title>
            <v-card-text class="mt-2">
              <table class="Normal-Table">
                <tbody>
                  <tr v-if="selectedDaDetails.source_address">
                    <th>Source Address</th>
                    <th class="px-2">:</th>
                    <td>
                      {{ selectedDaDetails.source_address }}
                    </td>
                  </tr>
                  <tr v-if="selectedDaDetails.mid_address">
                    <th>Mid Point Address</th>
                    <th class="px-2">:</th>
                    <td>
                      {{ selectedDaDetails.mid_address }}
                    </td>
                  </tr>
                  <tr v-if="selectedDaDetails.destination_address">
                    <th>Destination Address</th>
                    <th class="px-2">:</th>
                    <td>
                      {{ selectedDaDetails.destination_address }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-card-text>
          </v-card>
        </v-menu>

        <v-menu
          open-on-hover
          right
          offset-y
          v-if="selectedDaDetails.delivery_advice_containers"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" class="mx-2">
              mdi-train-car-container
            </v-icon>
          </template>
          <v-card>
            <v-card-title
              class="secondary white--text text-subtitle-1 font-weight-medium py-1"
            >
              Container Information
            </v-card-title>
            <v-card-text class="mt-2">
              <v-row>
                <v-col
                  cols="auto"
                  v-for="(
                    container, i
                  ) in selectedDaDetails.delivery_advice_containers"
                  :key="i"
                >
                  <v-card>
                    <v-card-title
                      class="grey lighten-2 py-1 text-subtitle-1 font-weight-medium"
                    >
                      <v-row>
                        <v-col cols="8" class="px-1">
                          <span class="d-flex" style="white-space: nowrap">
                            Container No : {{ container.container_no }}
                          </span>
                        </v-col>
                        <v-col cols="4" class="text-right px-1">
                          {{ container.status }}
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <v-card-text class="mt-2">
                      <table class="Normal-Table">
                        <tbody>
                          <tr v-if="container.container_type_title">
                            <th>Container Type</th>
                            <th class="px-2">:</th>
                            <td>
                              {{ container.container_type_title }}
                            </td>
                          </tr>
                          <tr v-if="container.weight">
                            <th>Container Weight(Ton)</th>
                            <th class="px-2">:</th>
                            <td>{{ container.weight }}</td>
                          </tr>
                          <tr v-if="container.seal_no">
                            <th>Seal No</th>
                            <th class="px-2">:</th>
                            <td>{{ container.seal_no }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-menu>
      </v-col>

      <v-col cols="12" lg="auto" class="d-flex align-center justify-end">
        <v-btn
          depressed
          color="primary"
          small
          class="ml-2"
          @click="getDABlob(selectedDaDetails.id, 'format1')"
          v-if="!['Cancelled'].includes(selectedDaDetails.status)"
        >
          Print Cont DA
        </v-btn>
        <v-btn
          depressed
          color="primary"
          small
          class="ml-2"
          @click="getDABlob(selectedDaDetails.id)"
          v-if="!['Cancelled'].includes(selectedDaDetails.status)"
        >
          Print Sub DA
        </v-btn>

        <v-btn
          depressed
          color="primary"
          small
          class="ml-2"
          @click="openCancelDaRemarkForm()"
          v-if="
            ['Created', 'Scheduled', 'Active'].includes(
              selectedDaDetails.status
            ) && !selectedDaDetails.sub_hire_bid
          "
        >
          Cancel
        </v-btn>
        <v-btn
          v-if="
            (selectedDaDetails.status == 'Created' ||
              selectedDaDetails.status == 'Scheduled' ||
              selectedDaDetails.status == 'Active' ||
              selectedDaDetails.status == 'Completed') &&
            permissions &&
            permissions.change
          "
          depressed
          color="primary"
          class="ml-2"
          small
          @click="
            selectedDaDetails.is_direct_order_da
              ? editDirectDeliveryAdvice(
                  selectedDaDetails.order,
                  selectedDaDetails.payment_term,
                  selectedDaDetails.id
                )
              : editDeliveryAdvice(selectedDaDetails.id)
          "
        >
          Edit
        </v-btn>

        <v-btn
          depressed
          color="primary"
          small
          class="ml-2"
          @click="replicaDialog = true"
          v-if="
            permissions &&
            permissions.change &&
            selectedDaDetails &&
            selectedDaDetails.payment_term == 'Credit'
          "
        >
          Replicate DA
        </v-btn>

        <v-btn
          depressed
          color="primary"
          class="ml-2"
          v-if="
            selectedDaDetails.status == 'Ready For Invoice' &&
            selectedDaDetails.payment_term == 'Credit'
          "
          small
          @click="undoReadyForInvoice"
        >
          Undo Ready For Invoice
        </v-btn>
        <v-btn
          depressed
          color="primary"
          class="ml-2"
          v-if="
            selectedDaDetails.status == 'Completed' &&
            selectedDaDetails.payment_term == 'Credit'
          "
          small
          @click="updateReadyToInvoice"
        >
          Ready For Invoice
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="ma-0" justify="space-between">
      <v-col cols="6" lg="auto" class="d-flex">
        <div v-if="selectedDaDetails.customer_name">
          <span class="text-caption">Customer</span>
          <p class="font-weight-medium mb-0">
            {{ selectedDaDetails.customer_name }}
          </p>
        </div>
        <v-divider vertical class="mx-3"></v-divider>
        <div v-if="selectedDaDetails.customer_contract_id">
          <span class="text-caption">Customer Tariff</span>
          <p class="font-weight-medium mb-0">
            {{ selectedDaDetails.customer_contract_id }}
          </p>
        </div>
      </v-col>

      <v-col cols="6" lg="auto" class="d-flex">
        <div v-if="selectedDaDetails.source_location_code">
          <span class="text-caption">Source</span>
          <p class="mb-0">
            <Tooltip
              bottom
              :tooltipTitle="selectedDaDetails.source_location_name"
              v-if="selectedDaDetails.source_location_code"
              :tooltipText="selectedDaDetails.source_location_code"
              tooltipClass="font-weight-medium"
            />
          </p>
        </div>

        <v-divider vertical class="mx-3"></v-divider>
        <div v-if="selectedDaDetails.mid_location_code">
          <span class="text-caption">Mid</span>
          <p class="mb-0">
            <Tooltip
              bottom
              :tooltipTitle="selectedDaDetails.mid_location_name"
              v-if="selectedDaDetails.mid_location_code"
              :tooltipText="selectedDaDetails.mid_location_code"
              tooltipClass="font-weight-medium"
            />
          </p>
        </div>
        <v-divider
          vertical
          class="mx-3"
          v-if="selectedDaDetails.destination_location_code"
        ></v-divider>
        <div v-if="selectedDaDetails.destination_location_code">
          <span class="text-caption">Destination</span>
          <p class="mb-0">
            <Tooltip
              bottom
              :tooltipTitle="selectedDaDetails.destination_location_name"
              v-if="selectedDaDetails.destination_location_code"
              :tooltipText="selectedDaDetails.destination_location_code"
              tooltipClass="font-weight-medium"
            />
          </p>
        </div>
      </v-col>

      <v-col cols="12" lg="auto" class="d-flex justify-end">
        <div v-if="selectedDaDetails.payment_term == 'Cash'">
          <span class="text-caption">Payment Receipt</span>
          <p class="mb-0">
            <v-btn depressed small icon @click="showPaymentReceiptForm = true">
              <v-icon>mdi-receipt-text-outline</v-icon>
            </v-btn>
          </p>
        </div>
        <v-divider vertical class="mx-3"></v-divider>
        <div>
          <span class="text-caption">Payment Terms</span>
          <p class="text-subtitle-1 font-weight-medium mb-0">
            {{ selectedDaDetails.payment_term }}
          </p>
        </div>
        <v-divider vertical class="mx-3"></v-divider>
        <div>
          <span class="text-caption">Currency</span>
          <p class="text-subtitle-1 font-weight-medium mb-0">
            {{ selectedDaDetails.currency }}
          </p>
        </div>
        <v-divider vertical class="mx-3"></v-divider>
        <div>
          <span class="text-caption">Rate </span>
          <p class="text-subtitle-1 font-weight-medium mb-0">
            {{ selectedDaDetails.rate }} &nbsp;{{ $globals.currency }}
          </p>
        </div>
        <v-divider vertical class="mx-3"></v-divider>
        <div>
          <span class="text-caption">Tax Amount</span>
          <p class="text-subtitle-1 font-weight-medium mb-0">
            {{ selectedDaDetails.tax_amount }} &nbsp;{{ $globals.currency }}
          </p>
        </div>
        <v-divider vertical class="mx-3"></v-divider>
        <div>
          <span class="text-caption"> Total Amount </span>
          <p class="text-subtitle-1 font-weight-medium mb-0">
            {{ selectedDaDetails.total_da_rate }} &nbsp;{{ $globals.currency }}
          </p>
        </div>
      </v-col>
    </v-row>

    <v-divider></v-divider>

    <v-row class="ma-0">
      <v-col cols="12" class="py-2">
        <v-tabs v-model="selectedDaDetailsTabs" height="35" class="mb-2">
          <v-tabs-slider></v-tabs-slider>
          <v-tab>Leg(s)</v-tab>
          <v-tab @click="paymentReceiptCharge"
            >Additional Charges &amp; Logs</v-tab
          >
          <v-tab>Attachments &amp; PODs</v-tab>
          <v-tab>Milestone</v-tab>
        </v-tabs>

        <v-tabs-items v-model="selectedDaDetailsTabs">
          <v-tab-item>
            <v-row class="ma-0">
              <v-col cols="12" class="py-0 px-1 mb-2">
                <v-form ref="legForm" v-model="isValid">
                  <v-card>
                    <v-card-title class="heading pa-0">
                      <v-row class="ma-0">
                        <v-col cols="12" sm="6" lg="8" class="py-2">
                          <span class="text-h6">
                            {{
                              isEditing
                                ? `Update Leg - ${legDetails.leg_id}`
                                : "Manage Leg"
                            }}
                          </span>
                        </v-col>

                        <v-col
                          cols="4"
                          class="d-flex justify-end py-2"
                          v-if="selectedDaDetails.status != 'Cancelled'"
                        >
                          <v-btn
                            small
                            color="primary"
                            class="text-capitalize"
                            depressed
                            :disabled="!isValid"
                            @click="manageLegAdd()"
                          >
                            {{ isEditing ? "Update" : "Add" }}
                          </v-btn>
                          <v-btn
                            text
                            small
                            color="primary"
                            class="text-capitalize ml-3"
                            depressed
                            @click="
                              clearEditing();
                              setLegMovementDateTime();
                            "
                            v-if="isEditing"
                          >
                            Cancel
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <v-card-text class="mt-2 px-0 pb-2">
                      <v-row class="ma-0">
                        <v-col cols="12" lg="3" class="Form-Columns-Dense">
                          <MultiSelect
                            label="Source *"
                            :items="allLocationList"
                            itemText="title"
                            itemValue="id"
                            :readonly="
                              legDetails &&
                              legDetails.status &&
                              legDetails.status != 'Assigned' &&
                              legDetails.status != 'Created'
                            "
                            :item-disabled="
                              (item) => {
                                return item.id == legDetails.mid_location;
                              }
                            "
                            :rules="[(val) => !!val || 'Source is required']"
                            hide-details="auto"
                            v-model="legDetails.source_location"
                            :error-messages="formErrors.source_location"
                            @input="delete formErrors.source_location"
                          />
                        </v-col>
                        <v-col cols="12" lg="3" class="Form-Columns-Dense">
                          <MultiSelect
                            label="Mid *"
                            :items="allLocationList"
                            itemText="title"
                            itemValue="id"
                            :item-disabled="
                              (item) => {
                                return (
                                  item.id == legDetails.destination_location ||
                                  item.id == legDetails.source_location
                                );
                              }
                            "
                            :rules="[(val) => !!val || 'Mid is required']"
                            hide-details="auto"
                            v-model="legDetails.mid_location"
                            :error-messages="formErrors.mid_location"
                            @input="delete formErrors.mid_location"
                          />
                        </v-col>
                        <v-col cols="12" lg="3" class="Form-Columns-Dense">
                          <MultiSelect
                            label="Destination"
                            :items="allLocationList"
                            itemText="title"
                            itemValue="id"
                            :clearable="true"
                            :item-disabled="
                              (item) => {
                                return item.id == legDetails.mid_location;
                              }
                            "
                            hide-details="auto"
                            v-model="legDetails.destination_location"
                            :error-messages="formErrors.destination_location"
                            @input="delete formErrors.destination_location"
                          />
                        </v-col>

                        <v-col cols="12" lg="3" class="Form-Columns-Dense">
                          <InputField
                            label="Plan Sequence"
                            type="text"
                            hide-details="auto"
                            v-model="legDetails.manual_sequence"
                            :error-messages="formErrors.manual_sequence"
                            @input="delete formErrors.manual_sequence"
                          ></InputField>
                        </v-col>
                        <v-col cols="12" lg="3" class="Form-Columns-Dense">
                          <DateField
                            :inputProps="{
                              dense: true,
                              label: 'Movement Date *',
                              rules: [
                                (val) => !!val || 'Movement Date is Required!',
                              ],
                              'hide-details': 'auto',
                              'error-messages': formErrors.movement_date,
                            }"
                            required
                            v-model="legDetails.movement_date"
                            @input="delete formErrors.movement_date"
                          />
                        </v-col>

                        <v-col cols="12" lg="3" class="Form-Columns-Dense">
                          <InputField
                            type="time"
                            label="Movement Time *"
                            :rules="[
                              (val) => !!val || 'Movement Time is Required!',
                            ]"
                            required
                            hide-details="auto"
                            v-model="legDetails.movement_time"
                            :error-messages="formErrors.movement_time"
                            @input="delete formErrors.movement_time"
                          />
                        </v-col>
                        <v-col cols="12" lg="3" class="Form-Columns-Dense">
                          <MultiSelect
                            label="Select Containers *"
                            :items="
                              selectedDaDetails.delivery_advice_containers
                            "
                            itemText="container_no"
                            itemValue="id"
                            :showAll="true"
                            :multiple="true"
                            :chips="true"
                            :item-disabled="
                              (item) => {
                                return item.status == 'Delivered';
                              }
                            "
                            :rules="[
                              (val) => !!val || 'Containers is required',
                              (val) =>
                                !!(Array.isArray(val) && val.length) ||
                                'Containers is required',
                            ]"
                            hideSelectAll="true"
                            hide-details="auto"
                            v-model="legDetails.leg_containers"
                            :error-messages="formErrors.leg_containers"
                            @input="
                              delete formErrors.leg_containers;
                              delete formErrors.mid_location;
                            "
                          />
                        </v-col>

                        <v-col cols="12" lg="3" class="Form-Columns-Dense">
                          <InputField
                            type="number"
                            label="Additional Bonus"
                            hide-details="auto"
                            v-model="legDetails.additional_bonus"
                            :error-messages="formErrors.additional_bonus"
                            @input="delete formErrors.additional_bonus"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          lg="2"
                          class="Form-Columns-Dense"
                          v-if="isEditing"
                        >
                          <MultiSelect
                            hide-details="auto"
                            label="Leg Type *"
                            :items="legTypeList"
                            :rules="[(val) => !!val || 'Leg Type is Required!']"
                            v-model="legDetails.leg_type"
                            :error-messages="formErrors.leg_type"
                            @input="delete formErrors.leg_type"
                          ></MultiSelect>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-form>
              </v-col>

              <v-col cols="6" class="Form-Columns-Dense">
                <span class="text-h5">Legs</span>
              </v-col>
              <v-col
                cols="6"
                class="Form-Columns-Dense text-right"
                v-if="selectedDaDetails.status != 'Cancelled'"
              >
                <v-btn
                  small
                  color="primary"
                  depressed
                  @click="resetLegsData"
                  :disabled="
                    !selectedDaDetails.can_reset_legs ||
                    ['Scheduled', 'Active'].indexOf(
                      this.selectedDaDetails.status
                    ) == -1 ||
                    selectedDaDetails.is_reset_button_enable
                  "
                >
                  Reset Legs
                </v-btn>

                <v-btn
                  small
                  class="ml-2"
                  color="primary"
                  depressed
                  :disabled="!selectedDaDetails.can_split_legs"
                  @click="splitLegs"
                >
                  Split Legs
                </v-btn>
              </v-col>

              <v-col
                cols="12"
                class="pa-0"
                v-if="legsList && legsList.length > 0"
              >
                <AgGridVue
                  style="width: 100%; height: calc(100vh - 474px)"
                  class="ag-theme-alpine"
                  id="myGrid"
                  :columnDefs="tripHeaders"
                  :context="context"
                  :grid-options="TripGridOptions"
                  :suppressDragLeaveHidesColumns="true"
                  :rowData="legsList"
                ></AgGridVue>
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item>
            <v-row class="ma-0">
              <v-col cols="12" lg="6" class="py-1 pl-1">
                <v-card>
                  <v-card-title class="heading py-2">
                    <span class="text-h6 font-weight-medium">
                      Additional Charges
                    </span>

                    <v-spacer></v-spacer>

                    <v-btn
                      v-if="
                        permissions &&
                        permissions.add &&
                        selectedDaDetails.status != 'Cancelled' &&
                        selectedDaDetails.status != 'Ready For Invoice' &&
                        selectedDaDetails.status != 'Invoice Generated' &&
                        selectedDaDetails.payment_term != 'Cash'
                      "
                      depressed
                      small
                      color="secondary"
                      @click="
                        editMode = false;
                        showDaChargeDialog = true;
                      "
                    >
                      Add Charges
                    </v-btn>
                  </v-card-title>
                  <v-card-text
                    class="pa-2"
                    v-if="legsList && legsList.length > 0"
                  >
                    <AgGridVue
                      style="width: 100%; height: calc(100vh - 390px)"
                      class="ag-theme-alpine"
                      id="deliveryChargeGrid"
                      :columnDefs="headers"
                      :context="context"
                      :grid-options="gridOptions"
                      :suppressDragLeaveHidesColumns="true"
                      :rowData="deliveryChargeList"
                    ></AgGridVue>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" lg="6" class="py-1 pr-1">
                <v-card>
                  <v-card-title class="heading py-2">
                    <span class="text-h6 font-weight-medium"> Logs </span>
                  </v-card-title>
                  <v-card-text class="pa-2 Cards-Height-Class">
                    <v-timeline dense clipped>
                      <v-timeline-item
                        v-for="(
                          log, i
                        ) in selectedDaDetails.delivery_advice_logs"
                        :key="i"
                        icon-color="primary"
                        small
                      >
                        <v-card class="elevation-2">
                          <v-card-text>
                            <table class="Full-Width">
                              <tbody>
                                <tr>
                                  <th class="text-left">{{ log.added_by }}</th>
                                  <td class="text-caption text-right">
                                    {{ log.created | formatDate(true) }}
                                  </td>
                                </tr>
                                <tr>
                                  <td colspan="2">{{ log.message }}</td>
                                </tr>
                              </tbody>
                            </table>
                          </v-card-text>
                        </v-card>
                      </v-timeline-item>
                    </v-timeline>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item class="fill-height">
            <v-row class="ma-0">
              <v-col cols="12" lg="6" class="py-1 pl-1">
                <v-card>
                  <v-card-title class="heading py-2">
                    <span class="text-h6 font-weight-medium">
                      Attachments
                    </span>

                    <v-spacer></v-spacer>

                    <v-btn
                      @click="showDocumentForm = true"
                      depressed
                      small
                      color="secondary"
                      v-if="
                        permissions &&
                        permissions.add &&
                        selectedDaDetails.status != 'Cancelled'
                      "
                    >
                      Add Attachments
                    </v-btn>
                  </v-card-title>
                  <v-card-text class="pa-2 Cards-Height-Class">
                    <DocumentList
                      :isDeletable="permissions && permissions.add"
                      @deleteDocument="deleteDeliveryAdviceDocument"
                      :documentList="selectedDaDetails.da_documents"
                    />
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" lg="6" class="py-1 pr-1">
                <v-card>
                  <v-card-title class="heading py-2">
                    <span class="text-h6 font-weight-medium"> PODs </span>
                    <v-spacer></v-spacer>
                    <v-btn
                      @click="showLegPODForm = true"
                      depressed
                      small
                      color="secondary"
                      v-if="
                        permissions &&
                        permissions.add &&
                        selectedDaDetails.status != 'Cancelled'
                      "
                    >
                      Add POD
                    </v-btn>
                  </v-card-title>
                  <v-card-text class="pa-2 Cards-Height-Class">
                    <PODList
                      :isDeletable="permissions && permissions.add"
                      @deleteDocument="deleteLegPOD"
                      :documentList="selectedDaDetails.da_pods"
                    />
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item class="fill-height">
            <v-row class="ma-0">
              <v-col cols="12" class="pa-1">
                <v-card>
                  <v-card-title class="heading py-2">
                    <span class="text-h6 font-weight-medium"> Milestones </span>
                  </v-card-title>
                  <v-card-text class="pa-2">
                    <v-row class="ma-0">
                      <v-col cols="10" class="Form-Columns-Dense">
                        <MultiSelect
                          :showAll="true"
                          :items="milestoneList"
                          itemText="milestone_name"
                          itemValue="id"
                          label="Select Milestones"
                          required
                          :multiple="true"
                          :chips="true"
                          hide-details="auto"
                          v-model="milestones"
                        ></MultiSelect>
                      </v-col>
                      <v-col cols="2" class="Form-Columns-Dense">
                        <v-btn
                          @click="submitMilestones"
                          depressed
                          color="primary"
                          v-if="
                            permissions &&
                            permissions.add &&
                            selectedDaDetails.status != 'Cancelled'
                          "
                        >
                          Submit
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <DeliveryAdviceChargeForm
      :editMode="editMode"
      v-model="showDaChargeDialog"
      :daDetails="selectedDaDetails"
      :chargeDetails="deliveryAdviceChargeDetails"
      @refreshList="getDeliveryAdviceChargesList()"
    />
    <RejectionRemarksDialog
      v-model="showRemarksForm"
      :formDetails="daCancellationRemark"
      fieldKey="admin_remarks"
      :isRequiredRemarks="true"
      :label="`${!isAccepted ? 'Cancellation' : 'Acceptance'} Remarks `"
      @submitRemarks="cancelDeliveryAdvice"
    />

    <v-dialog v-model="viewTripDetails" width="70%">
      <v-card>
        <v-card-title class="secondary white--text px-4 py-2">
          Leg - {{ selectedTripDetails.leg_id }}

          <v-spacer></v-spacer>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="white"
                icon
                @click="viewTripDetails = false"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Close</span>
          </v-tooltip>
        </v-card-title>

        <v-card-text class="mt-2">
          <v-row class="my-0">
            <v-col cols="12" lg="4" class="Form-Columns">
              <span class="text-caption">Source Location</span>
              <p class="text-subtitle-1 font-weight-medium mb-0">
                {{ selectedTripDetails.source_location_name }}
              </p>
            </v-col>

            <v-col cols="12" lg="4" class="Form-Columns">
              <span class="text-caption">Mid Location</span>
              <p class="text-subtitle-1 font-weight-medium mb-0">
                {{ selectedTripDetails.mid_location_name }}
              </p>
            </v-col>
            <v-col cols="12" lg="4" class="Form-Columns">
              <span class="text-caption">Destination Location</span>
              <p class="text-subtitle-1 font-weight-medium mb-0">
                {{ selectedTripDetails.destination_location_name }}
              </p>
            </v-col>

            <v-col cols="12" lg="4" class="Form-Columns">
              <span class="text-caption">Source Address</span>
              <p class="text-subtitle-1 font-weight-medium mb-0">
                {{ selectedTripDetails.source_address }}
              </p>
            </v-col>

            <v-col cols="12" lg="4" class="Form-Columns">
              <span class="text-caption">mid Address</span>
              <p class="text-subtitle-1 font-weight-medium mb-0">
                {{ selectedTripDetails.mid_address }}
              </p>
            </v-col>
            <v-col cols="12" lg="4" class="Form-Columns">
              <span class="text-caption">Destination Address</span>
              <p class="text-subtitle-1 font-weight-medium mb-0">
                {{ selectedTripDetails.destination_address }}
              </p>
            </v-col>

            <v-col
              cols="12"
              md="6"
              v-if="selectedTripDetails.vehicle_number"
              class="Form-Columns"
            >
              <span class="text-caption">Vehicle</span>
              <p class="text-subtitle-1 font-weight-medium mb-0">
                {{ selectedTripDetails.vehicle_number }}
              </p>
            </v-col>

            <v-col
              cols="12"
              md="6"
              v-if="selectedTripDetails.trailer_1_number"
              class="Form-Columns"
            >
              <span class="text-caption">Trailer 1</span>
              <p class="text-subtitle-1 font-weight-medium mb-0">
                {{ selectedTripDetails.trailer_1_number }}
              </p>
            </v-col>

            <v-col
              cols="12"
              md="6"
              v-if="selectedTripDetails.trailer_2_number"
              class="Form-Columns"
            >
              <span class="text-caption">Trailer 2</span>
              <p class="text-subtitle-1 font-weight-medium mb-0">
                {{ selectedTripDetails.trailer_2_number }}
              </p>
            </v-col>

            <v-col
              cols="12"
              md="6"
              v-if="selectedTripDetails.driver_name"
              class="Form-Columns"
            >
              <span class="text-caption">Driver</span>
              <p class="text-subtitle-1 font-weight-medium mb-0">
                {{ selectedTripDetails.driver_name }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <DaReplicaDialog
      @refreshList="$emit('refreshList')"
      v-model="replicaDialog"
      :daDetails="selectedDaDetails"
    />
    <DeliveryAdviceDocumentUpload
      v-model="showDocumentForm"
      :daID="selectedDaDetails.id"
      @refreshObject="getDeliveryAdviceDetails()"
    />
    <LegPODUpload
      v-model="showLegPODForm"
      :daLegList="legsList"
      :daID="selectedDaDetails.id"
      @refreshObject="getDeliveryAdviceDetails()"
    />
    <PaymentReceiptForm
      v-model="showPaymentReceiptForm"
      :paymentReceiptID="selectedDaDetails.payment_receipt"
      :daId="selectedDaDetails.id"
      :customer="selectedDaDetails.customer"
      :laneId="selectedDaDetails.lane"
      :jobType="selectedDaDetails.job_type"
      @refreshList="getDeliveryAdviceDetails()"
    />
    <LegBonusUpdateForm
      v-model="showLegBonusDialog"
      :legBonus="bonus"
      :legId="selectedLegId"
      @refreshList="getLegsList()"
    />

    <SplitLegRateDialog
      v-model="showLegSplitDialog"
      :legDetails="legsList"
      :type="splitLegType"
      :daDetails="selectedDaDetails"
      @refreshList="getLegsList(), getDeliveryAdviceDetails()"
      @splitRateData="handleSplitRateData"
    />
    <SplitWithoutSubhireLegRateDialog
      v-model="showWithoutSubhireLegSplitDialog"
      :legDetails="legsList"
      :type="splitLegType"
      :daDetails="selectedDaDetails"
      @refreshList="getLegsList(), getDeliveryAdviceDetails()"
      @splitRateData="handleWithoutSubHireSplitRateData"
    />
    <ConfirmationDialogue
      v-model="showConfirmationDialogue"
      :status="status"
      :title="title"
      :text="text"
    />
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import Tooltip from "@/components/General/Tooltip.vue";
import PaymentReceiptForm from "@/components/ModuleBased/dialogForms/PaymentReceiptForm.vue";
import DocumentList from "@/components/General/DocumentList.vue";
import PODList from "@/components/General/PODList.vue";
import DeliveryAdviceChargeForm from "@/components/ModuleBased/dialogForms/DeliveryAdviceChargeForm.vue";
import SplitLegRateDialog from "@/components/ModuleBased/dialogForms/SplitLegRateDialog.vue";
import SplitWithoutSubhireLegRateDialog from "@/components/ModuleBased/dialogForms/SplitWithoutSubhireLegRateDialog.vue";

import DeliveryChargeButton from "@/components/AgGridComponents/DeliveryChargeButton.vue";
import DeliveryAdviceTripInfoButton from "@/components/AgGridComponents/DeliveryAdviceTripInfoButton.vue";
import DeliveryAdviceDocumentUpload from "@/components/ModuleBased/dialogForms/DeliveryAdviceDocumentUpload.vue";
import LegPODUpload from "@/components/ModuleBased/dialogForms/LegPODUpload.vue";
import DaReplicaDialog from "@/components/ModuleBased/dialogForms/DaReplicaDialog.vue";
import LegButton from "@/components/AgGridComponents/LegButton.vue";
import RejectionRemarksDialog from "@/components/ModuleBased/dialogs/RejectionRemarksDialog.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";
import LegBonusUpdateForm from "@/components/ModuleBased/dialogForms/LegBonusUpdateForm.vue";
import ConfirmationDialogue from "@/components/General/ConfirmationDialogue.vue";

import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    AgGridVue,
    InputField,
    MultiSelect,
    ConfirmationDialogue,
    PaymentReceiptForm,
    SplitWithoutSubhireLegRateDialog,
    SplitLegRateDialog,
    Tooltip,
    DocumentList,
    DaReplicaDialog,
    DeliveryChargeButton,
    DeliveryAdviceChargeForm,
    PODList,
    LegPODUpload,
    DeliveryAdviceTripInfoButton,
    DeliveryAdviceDocumentUpload,
    LegButton,
    RejectionRemarksDialog,
    DateField,
    LegBonusUpdateForm,
  },

  data() {
    return {
      backRoute: {},
      selectedDaDetails: {
        da_documents: [],
        da_pods: [],
      },
      isValid: true,
      showConfirmationDialogue: false,
      isEditing: false,
      showLegSplitDialog: false,
      showWithoutSubhireLegSplitDialog: false,
      splitLegType: null,
      permissions: null,
      tripPermissions: null,
      allTransporterList: [],
      allLocationList: [],
      formErrors: [],
      getListCalled: false,
      status: "secondery",
      title: "Confirmation",
      text: "Are you sure you want to Reset?",
      legDetails: {
        leg_containers: [],
      },
      showPaymentReceiptForm: false,

      selectedDaDetailsTabs: 0,

      // ag-grid vars
      gridApi: null,
      gridColumnApi: null,
      TripGridOptions: {
        onGridSizeChanged: (grid) => {
          if (grid && grid.api) {
            grid.api.sizeColumnsToFit();
          }
        },
        defaultColDef: {
          resizable: true,
        },
        rowHeight: 35,
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
      },
      tripHeaders: [
        {
          headerName: "Leg ID",
          field: "leg_id",
          minWidth: 130,
        },
        {
          headerName: "Leg Type",
          field: "leg_type",
          minWidth: 100,
        },
        {
          headerName: "Sequence",
          field: "manual_sequence",
          minWidth: 85,
        },
        {
          headerName: "Company Name",
          field: "company_name",
          tooltipField: "company_name",
          minWidth: 150,
        },
        {
          headerName: "Containers",
          field: "leg_container_nos",
          tooltipField: "leg_container_nos",
          minWidth: 140,
        },
        {
          headerName: "Source",
          field: "source_location_code",
          tooltipField: "source_location_name",
          minWidth: 70,
        },
        {
          headerName: "Mid",
          field: "mid_location_code",
          tooltipField: "mid_location_name",
          minWidth: 65,
        },
        {
          headerName: "Destination",
          field: "destination_location_code",
          tooltipField: "destination_location_name",
          minWidth: 65,
        },
        {
          headerName: "Rate",
          field: "rate",
          minWidth: 130,
        },
        {
          headerName: "Movement Date",
          field: "movement_date",
          minWidth: 100,
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value);
          },
          tooltipValueGetter: (params) => {
            return this.$globals.formatDate(params.value);
          },
        },
        {
          headerName: "Time",
          field: "movement_time",
          tooltipField: "movement_time",
          minWidth: 40,
        },

        {
          headerName: `Bonus (${this.$globals.currency})`,
          field: "bonus",
          minWidth: 65,
          onCellClicked: (params) => {
            if (
              params &&
              params.data &&
              params.data.status != "Invoice Generated" &&
              params.data.status != "Cancelled"
            ) {
              this.selectedLegId = [];
              this.bonus = params.data.bonus;
              this.selectedLegId.push(params.data.id);
              this.showLegBonusDialog = true;
            }
          },
        },
        {
          headerName: `Additional Bonus (${this.$globals.currency})`,
          field: "additional_bonus",
          minWidth: 65,
        },
        {
          headerName: "Status",
          field: "status",
          minWidth: 60,
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRendererFramework: "LegButton",
          pinned: "right",
          minWidth: 150,
        },
      ],

      legsList: [],
      tripGridApi: null,
      tripGridColumnApi: null,

      headers: [
        {
          headerName: "Charge Title",
          field: "charge_title",
          tooltipField: "charge_title",
          minWidth: 280,
        },
        {
          headerName: "Revenue GL No.",
          field: "gl_no",
          tooltipField: "gl_no",
          minWidth: 100,
        },

        {
          headerName: `Rate (${this.$globals.currency})`,
          field: "rate",
          minWidth: 100,
        },

        {
          headerName: "Tax (%)",
          field: "tax_percentage",
          minWidth: 100,
        },
        {
          headerName: `Tax Amount (${this.$globals.currency})`,
          field: "tax",
          minWidth: 100,
          valueFormatter: (params) => {
            if (params.data && params.data.tax) {
              return params.data.tax;
            } else {
              return params.data.tax_amount;
            }
          },
        },
        {
          headerName: `Total Amount (${this.$globals.currency})`,
          field: "amount_with_tax",
          minWidth: 120,
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRendererFramework: "DeliveryChargeButton",
          pinned: "right",
          minWidth: 130,
        },
      ],

      gridOptions: {
        //  onGridReady: function(params) {
        //     params.api.setRowData(...this.deliveryChargeList);
        // },
        onGridSizeChanged: (grid) => {
          if (grid && grid.api) {
            grid.api.sizeColumnsToFit();
          }
        },
        defaultColDef: {
          resizable: true,
        },
        rowHeight: 35,
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
      },

      daCancellationRemark: {},
      showRemarksForm: false,
      isAccepted: false,

      // Dialog vars
      viewTripDetails: false,
      selectedTripDetails: {},
      bonus: null,
      showLegBonusDialog: false,
      selectedLegId: [],
      legTypeList: [],

      // charge Form vars
      showDaChargeDialog: false,
      editMode: false,
      deliveryAdviceChargeDetails: null,

      showDocumentForm: false,
      showLegPODForm: false,
      splitFlag: false,

      milestones: [],
      milestoneList: [],
      selectedMilestones: [],
      showMilestoneForm: false,

      replicaDialog: false,

      deliveryChargeList: [],
      allChargeList: [],
      splitRateData: {},
    };
  },

  computed: {
    context() {
      return { parentComponent: this };
    },
    currentProfile() {
      if (JSON.parse(localStorage.getItem("userProfile"))) {
        return JSON.parse(localStorage.getItem("userProfile"));
      } else {
        return null;
      }
    },
  },
  methods: {
    handleSplitRateData(splitDataRate) {
      this.splitRateData = splitDataRate;
      this.splitFlag = true;
      this.submitForm();
      this.showLegSplitDialog = false;
    },
    handleWithoutSubHireSplitRateData(splitDataRate) {
      this.splitRateData = splitDataRate;
      this.splitFlag = true;
      this.submitForm();
      this.showWithoutSubhireLegSplitDialog = false;
    },
    resetSubhireLegs() {
      const config = {
        status: "secondary",
        title: "Confirmations",
        text: "Are you sure you want to Reset?",
      };
      this.$confirm(config).then(() => {
        this.$bus.$emit("showLoader", true);
        this.$api.deliveryAdvice
          .resetSubHireLegs(this.$route.params.id, {})
          .then((res) => {
            this.clearEditing();
            if (!this.getListCalled) {
              this.getListCalled = true;
              this.getLists([
                this.getDeliveryAdviceDetails(),
                this.getLegsList(),
              ]);
            }
            this.$bus.$emit("showToastMessage", {
              message: "Leg reset operation successfully completed!",
              color: "success",
            });
          })
          .catch((err) => {
            console.error(err);
          });
      });
    },
    viewDeliveryAdvicesDetails(id) {
      this.$router.push({
        path: `/app/admin/delivery-advice/details/${id}`,
        query: { backRoute: `/app/admin/delivery-advice/list` },
      });
      setTimeout(() => {
        this.permissions = getPermissions("delivery_advice");
        this.tripPermissions = getPermissions("leg_trip");
        this.getAllLocationList();
        this.getDeliveryAdviceDetails();
        // this.getDeliveryAdviceChargesList();
        // this.getLegsList();
        // this.getMilestoneList();
      }, 20);
    },
    getLegOptions() {
      this.$bus.$emit("showLoader", true);
      this.$api.deliveryAdvice
        .getLegOptions()
        .then((res) => {
          this.legTypeList = res.data.actions.POST.leg_type.choices.map(
            (obj) => {
              return { text: obj.display_name, value: obj.value };
            }
          );

          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getDaDetails(changeKey) {
      this.$bus.$emit("showLoader", true);
      let payload = {
        id: this.selectedDaDetails.id,
        da_change: changeKey,
      };
      this.$api.deliveryAdvice
        .prevNextDa(payload)
        .then((res) => {
          this.viewDeliveryAdvicesDetails(res.data.da_id);
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "DA not found!",
            color: "error",
          });
        });
    },
    getDABlob(id, daDownload = null) {
      let name;
      if (daDownload) {
        name = "ContDA";
      } else {
        name = "SubDA";
      }
      this.$bus.$emit("showLoader", true);
      let params = {
        da_download: name,
      };
      this.$api.deliveryAdvice
        .getDABlob(id, params)
        .then((resp) => {
          let blob = new Blob([resp.data], {
            type: resp.headers["content-type"],
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = name + ".pdf";
          link.click();
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    paymentReceiptCharge() {
      if (this.selectedDaDetails.payment_term == "Cash") {
        this.$api.paymentReceipt
          .getPaymentReceiptDetails(this.selectedDaDetails.payment_receipt)
          .then((res) => {
            this.deliveryChargeList = res.data.payment_receipt_charges;
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't Fetch payment receipt Charge!",
              color: "error",
            });
            this.formErrors = err.data;
          });
      }
    },
    openCancelDaRemarkForm(id) {
      this.cancelDaId = id;
      this.showRemarksForm = true;
    },
    cancelDeliveryAdvice() {
      this.$bus.$emit("showLoader", true);
      let payload = {
        id: this.selectedDaDetails.id,
        cancel_remarks: this.daCancellationRemark.admin_remarks,
      };
      this.$api.deliveryAdvice
        .cancelDeliveryAdvice(payload)
        .then((res) => {
          this.getLegsList();
          this.getDeliveryAdviceDetails();
          this.showRemarksForm = false;
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't cancel DA!",
            color: "error",
          });
        });
    },
    getLegsList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        ...params,
        delivery_advice: this.$route.params.id,
      };
      this.$api.trip
        .getLegs(params)
        .then((res) => {
          this.legsList = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          console.error(err);
        });
    },
    deleteLeg(id) {
      const idToRemove = id;
      const result = this.legsList.filter((obj) => obj.id !== idToRemove);

      const areEqual = result.every((obj) => obj["status"] === "Completed");

      if (areEqual) {
        this.$confirm({
          status: "secondery",
          title: "Warning",
          color: "danger",
          text: "Removing this Leg will change the Status of DA to COMPLETE ?",
        })
          .then(() => {
            this.$bus.$emit("showLoader", true);
            this.$api.trip
              .deleteLeg(id)
              .then((res) => {
                this.clearEditing();
                if (!this.getListCalled) {
                  this.getListCalled = true;
                  this.getLists([
                    this.getDeliveryAdviceDetails(),
                    this.getLegsList(this.$route.params.id),
                  ]);
                }
                this.$bus.$emit("showToastMessage", {
                  message: "Leg deleted successfully!",
                  color: "error",
                });
              })
              .catch((err) => {
                this.$bus.$emit("showLoader", false);
                console.error(err);
              });
          })
          .catch(() => {
            console.log("Rejected");
          });
      } else {
        this.$confirm({
          status: "secondery",
          title: "Warning",
          color: "danger",
          text: "Are you sure you want to Delete?",
        })
          .then(() => {
            this.$bus.$emit("showLoader", true);
            this.$api.trip.deleteLeg(id).then((res) => {
              this.clearEditing();
              if (!this.getListCalled) {
                this.getListCalled = true;
                this.getLists([
                  this.getDeliveryAdviceDetails(),
                  this.getLegsList(this.$route.params.id),
                ]);
              }
              this.$bus.$emit("showToastMessage", {
                message: "Leg deleted successfully!",
                color: "error",
              });
            });
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            console.error(err);
          });
      }
    },
    viewLegInfo(trip) {
      this.selectedTripDetails = trip;
      this.viewTripDetails = true;
    },

    undoReadyForInvoice() {
      if (confirm("Are you sure you want to move it to back to completed?")) {
        this.$bus.$emit("showLoader", true);
        this.$api.deliveryAdvice
          .undoDAReadyForInvoiceStatus(this.selectedDaDetails.id)
          .then((res) => {
            this.getDeliveryAdviceDetails(),
              this.$bus.$emit("showToastMessage", {
                message: "Status Updated Successfully!",
                color: "success",
              });
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);

            this.$bus.$emit("showToastMessage", {
              message: `${
                err.data && err.data.non_field_errors
                  ? err.data.non_field_errors.join()
                  : "Cant update status"
              }`,
              color: "error",
            });
          });
      }
    },
    updateReadyToInvoice() {
      if (confirm("Are you sure you want to move it to ready for invoice.")) {
        this.$bus.$emit("showLoader", true);
        this.$api.deliveryAdvice
          .updateDaStatus({
            id: this.selectedDaDetails.id,
            data: { status: "Ready For Invoice" },
          })
          .then((res) => {
            this.getDeliveryAdviceDetails(),
              this.$bus.$emit("showToastMessage", {
                message: "Status Updated Successfully!",
                color: "success",
              });
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);

            this.$bus.$emit("showToastMessage", {
              message: `${
                err.data && err.data.non_field_errors
                  ? err.data.non_field_errors.join()
                  : "Cant update status"
              }`,
              color: "error",
            });
          });
      }
    },
    deleteDeliveryCharge(id) {
      if (confirm("Are you sure you want to delete")) {
        this.$bus.$emit("showLoader", true);
        this.$api.deliveryAdviceCharge
          .deleteDeliveryCharge(id)
          .then((res) => {
            this.getDeliveryAdviceChargesList();
            this.$bus.$emit("showLoader", false);
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't delete delivery charge list!",
              color: "error",
            });
          });
      }
    },
    editDeliveryCharge(data) {
      this.showDaChargeDialog = true;
      this.editMode = true;
      this.deliveryAdviceChargeDetails = data;
    },
    callBackRoute() {
      if (Object.keys(this.backRoute).length === 0) {
        this.$router.push("/app/admin/delivery-advice/list");
      } else {
        this.$router.push(this.backRoute);
      }
    },
    setBackRoute() {
      if ("backRoute" in this.$route.query) {
        this.backRoute.path = this.$route.query.backRoute;
      }
      if ("active_tab" in this.$route.query) {
        if (!this.backRoute.query) {
          this.backRoute.query = {};
        }
        this.backRoute.query.active_tab = this.$route.query.active_tab;
      }
    },

    editDeliveryAdvice(id) {
      this.$router.push({
        path: `/app/admin/delivery-advice/edit/${id}`,
        query: {
          backRoute: `/app/admin/delivery-advice/details/${this.selectedDaDetails.id}`,
        },
      });
    },
    editDirectDeliveryAdvice(orderId, payment_term, daId) {
      if (payment_term && payment_term == "Cash") {
        this.$router.push({
          path: `/app/admin/delivery-advice/cash-da-edit/${daId}`,
          query: {
            backRoute: `/app/admin/delivery-advice/list`,
          },
        });
      } else {
        this.$router.push({
          path: `/app/admin/delivery-advice/direct-da-edit/${orderId}`,
          query: {
            backRoute: `/app/admin/delivery-advice/details/${this.selectedDaDetails.id}`,
          },
        });
      }
    },
    setLegMovementDateTime() {
      if (this.legDetails && this.selectedDaDetails) {
        if (
          !this.legDetails.movement_date &&
          this.selectedDaDetails.movement_date
        ) {
          this.legDetails.movement_date = this.selectedDaDetails.movement_date;
        }
        if (
          !this.legDetails.movement_time &&
          this.selectedDaDetails.movement_time
        ) {
          this.legDetails.movement_time = this.selectedDaDetails.movement_time;
        }
      }
    },
    getAllLocationList() {
      this.$api.location
        .getLocationSelectList({ limit: "all" })
        .then((res) => {
          this.allLocationList = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getDeliveryAdviceDetails() {
      this.$bus.$emit("showLoader", true);
      this.$api.deliveryAdvice
        .getDeliveryAdviceDetails(this.$route.params.id)
        .then((res) => {
          this.selectedDaDetails = res.data;
          this.getLegsList();
          this.getMilestoneList();
          this.getAllMilestoneList();
          this.getDeliveryAdviceChargesList();
          this.setLegMovementDateTime();
          this.selectedDaDetailsTabs = 0;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    editLeg(legObject) {
      this.isEditing = true;
      this.formErrors = {};
      this.legDetails = { ...legObject };
      this.legDetails.leg_containers = this.legDetails.leg_containers.map(
        (item) => item.order_container
      );
      this.setLegMovementDateTime();
    },
    getLists(apis) {
      this.$bus.$emit("showLoader", true);

      return new Promise((resolve, reject) => {
        Promise.all(apis)
          .then((val) => {
            this.getListCalled = false;
            this.$bus.$emit("showLoader", false);
            resolve();
          })
          .catch((err) => {
            this.getListCalled = false;
            this.$bus.$emit("showLoader", false);
            reject();
          });
      });
    },
    manageLegAdd() {
      if (this.isEditing === true && this.legsList.length >= 1) {
        this.submitForm();
      } else if (
        this.selectedDaDetails.is_sub_hire == "Yes" &&
        this.isEditing === false &&
        this.legsList.length >= 1
      ) {
        this.showLegSplitDialog = true;
        this.splitLegType = "MultiSplit";
      } else if (
        this.selectedDaDetails.is_sub_hire == "No" &&
        this.isEditing === false &&
        this.legsList.length >= 1
      ) {
        this.showWithoutSubhireLegSplitDialog = true;
        this.splitLegType = "MultiSplit";
        // this.submitForm();
      }
    },

    submitForm() {
      this.$bus.$emit("showLoader", true);

      let payload = {
        ...this.legDetails,
        company: this.currentProfile.company,
        delivery_advice: this.$route.params.id,
        leg_containers: [...this.legDetails.leg_containers],
      };
      if (this.splitFlag) {
        payload.split_rate_data = this.splitRateData;
      }

      if (payload.leg_containers) {
        payload.leg_containers.forEach((val, index) => {
          payload.leg_containers[index] = {
            order_container: val,
          };
        });
      }
      if (this.isEditing) {
        this.$api.trip
          .updateLeg({ id: this.legDetails.id, data: payload })
          .then((res) => {
            this.clearEditing();
            if (!this.getListCalled) {
              this.getListCalled = true;
              this.getLists([
                this.getDeliveryAdviceDetails(),
                this.getLegsList(),
              ]);
            }

            this.$bus.$emit("showToastMessage", {
              message: "Leg updated successfully!",
              color: "success",
            });
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            if (err && err.data) {
              this.formErrors = err.data;
            }
            if (err && err.data && err.data.non_field_errors) {
              this.$bus.$emit("showToastMessage", {
                message: err.data.non_field_errors[0],
                color: "error",
                hasActions: true,
              });
            }
          });
      } else {
        this.$api.trip
          .addLeg(payload)
          .then((res) => {
            this.clearEditing();
            if (!this.getListCalled) {
              this.getListCalled = true;
              this.getLists([
                this.getAllLocationList(),
                this.getDeliveryAdviceDetails(),
                this.getLegsList(),
              ]);
            }
            this.$bus.$emit("showToastMessage", {
              message: "Leg Added successfully!",
              color: "success",
            });
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            if (err && err.data) {
              this.formErrors = err.data;
            }
            if (err && err.data && err.data.non_field_errors) {
              this.$bus.$emit("showToastMessage", {
                message: err.data.non_field_errors[0],
                color: "error",
                hasActions: true,
              });
            }
          });
      }
    },
    clearEditing() {
      this.isEditing = false;
      this.legDetails = { leg_containers: [] };
      this.allTransporterList = [];
      this.formErrors = {};
      this.resetFormValidation();
    },
    resetFormValidation() {
      let form = this.$refs.legForm;
      if (form) {
        form.resetValidation();
      }
    },

    resetLegsData() {
      if (this.selectedDaDetails.is_sub_hire == "Yes") {
        const config = {
          status: "secondary",
          title: "Confirmations",
          text: "Are you sure you want to Reset?",
        };
        this.$confirm(config).then(() => {
          this.$bus.$emit("showLoader", true);
          this.$api.deliveryAdvice
            .resetSubHireLegs(this.$route.params.id, {})
            .then((res) => {
              this.clearEditing();
              if (!this.getListCalled) {
                this.getListCalled = true;
                this.getLists([
                  this.getDeliveryAdviceDetails(),
                  this.getLegsList(),
                ]);
              }
              this.$bus.$emit("showToastMessage", {
                message: "Leg reset operation successfully completed!",
                color: "success",
              });
            })
            .catch((err) => {
              console.error(err);
            });
        });
      } else if (confirm("Are you sure you want to reset legs.")) {
        this.$bus.$emit("showLoader", true);

        this.$api.deliveryAdvice
          .resetLegs(this.$route.params.id, {})
          .then((res) => {
            this.clearEditing();
            if (!this.getListCalled) {
              this.getListCalled = true;
              this.getLists([
                this.getDeliveryAdviceDetails(),
                this.getLegsList(),
              ]);
            }
            this.$bus.$emit("showToastMessage", {
              message: "Leg reset operation successfully completed!",
              color: "success",
            });
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    splitLegs() {
      if (this.selectedDaDetails.is_sub_hire == "Yes") {
        this.showLegSplitDialog = true;
        this.splitLegType = "Base";
      } else {
        this.showWithoutSubhireLegSplitDialog = true;
        this.splitLegType = "Base";
      }
    },

    deleteDeliveryAdvice(da) {
      if (confirm(`Are you sure you want to delete ${da.da_no}`)) {
        this.$bus.$emit("showLoader", true);
        this.$api.deliveryAdvice
          .deleteDeliveryAdvice(da.id)
          .then((res) => {
            this.$bus.$emit("showToastMessage", {
              message: `Delivery Advice ${da.da_no} deleted successfully!`,
              color: "success",
            });
            this.$emit("refreshList");
            this.$emit("clearObject");
            this.$bus.$emit("showLoader", false);
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: `Couldn't delete delivery advice!`,
              color: "error",
            });
          });
      }
    },
    deleteDeliveryAdviceDocument(id) {
      if (confirm("Are you sure you want to delete this document?"))
        this.$bus.$emit("showLoader", true);
      this.$api.deliveryAdvice
        .deleteDeliveryAdviceDocument(id)
        .then(() => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Document deleted successfully!",
            color: "success",
          });
          this.getDeliveryAdviceDetails();
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't delete document!",
            color: "error",
          });
        });
    },
    deleteLegPOD(id) {
      if (confirm("Are you sure you want to delete this POD?"))
        this.$bus.$emit("showLoader", true);
      this.$api.trip
        .deleteLegPOD(id)
        .then(() => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "POD deleted successfully!",
            color: "success",
          });
          this.getDeliveryAdviceDetails();
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't delete POD!",
            color: "success",
          });
        });
    },

    getAllMilestoneList() {
      const params = {
        job_type: this.selectedDaDetails.job_type,
      };

      this.$api.milestone
        .getMilestoneList(params)
        .then((res) => {
          this.milestoneList = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getMilestoneList() {
      this.$api.deliveryAdvice
        .getDaMilestoneList({
          delivery_advice: this.selectedDaDetails.id,
        })
        .then((res) => {
          this.selectedMilestones = res.data;

          this.milestones = res.data.map((m) => m.milestone);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    submitMilestones() {
      this.$bus.$emit("showLoader", true);
      this.$api.deliveryAdvice
        .addDeliveryAdviceMilestones({
          id: this.selectedDaDetails.id,
          data: { milestone_ids: this.milestones },
        })
        .then((res) => {
          this.getMilestoneList();
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Milestones updated successfully!",
            color: "success",
          });
        })
        .catch((err) => {
          this.getMilestoneList();
          console.error(err);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't update milestones!",
            color: "error",
          });
        });
    },

    getDeliveryAdviceChargesList() {
      this.$api.deliveryAdviceCharge
        .getDeliveryAdviceChargesList({
          delivery_advice: this.$route.params.id,
          limit: "all",
        })
        .then((res) => {
          this.deliveryChargeList = res.data;
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't Fetch delivery charge list!",
            color: "error",
          });
          this.formErrors = err.data;
        });
    },
  },
  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      this.permissions = getPermissions("delivery_advice");
      this.tripPermissions = getPermissions("leg_trip");
      if (!this.permissions || !this.permissions.view) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.getAllLocationList();
        this.getDeliveryAdviceDetails();
        // this.getDeliveryAdviceChargesList();
        // this.getLegsList();
        // this.getMilestoneList();
        // this.getAllMilestoneList();
        this.setBackRoute();
        this.getLegOptions();
      }
    }
  },
};
</script>

<style scoped>
.Cards-Height-Class {
  height: calc(100vh - 374px);
  overflow-y: auto;
}
</style>
